import React from 'react';
import { Link } from 'gatsby';
import GRAPHIC from '../../../images/specials/hero/Sep24-Badge.svg';

function SocialHeroContent({ setShowSignUpModal }) {
  console.log(
    'setshowsignupmodal in SOCIALHEROCONTENT component:',
    setShowSignUpModal
  );
  return (
    <div id='social-hero-content' className='container'>
      <div className='row'>
        <div className='offset-xs-1 col-md-6 offset-md-6'>
          <figure className='d-none d-md-block pb-4 text-center'>
            <img
              id='graphic'
              src={GRAPHIC}
              alt='Milan Laser Social'
              style={{ width: '78.65%' }}
            />
          </figure>
          <p className='pt-3 pt-md-none'>
            Not so secret ‘stache? This month, get one FREE treatment when you
            book a consultation, and 50% OFF unlimited laser hair removal. Plus,
            for the first time ever, we’re offering a{' '}
            <strong>FREE body area</strong> with a regular package purchase this
            month only!
          </p>
          <p>
            Our{' '}
            <Link to='#lp-unlimited-package' className='milan-text-primary'>
              exclusive Unlimited Package™
            </Link>{' '}
            comes with every purchase and includes FREE touch-ups, so you’re
            covered for life at any of our 380+ locations free of charge. Stop
            plucking around and visit your{' '}
            <Link to='/locations' className='milan-text-primary'>
              local Milan Laser
            </Link>{' '}
            by <strong>September 30</strong> to get half-off unlimited laser
            hair removal for life and <strong>one FREE body area!</strong> See
            clinic for details.
          </p>
          <div className='mt-4 text-center'>
            <Link className='milan-btn milan-cta-btn w-auto' to='#consult'>
              Book My Free Consult
            </Link>
          </div>
          <div className='mt-4'>
            <p className='mb-0 milan-fp pb-milan-50 pb-md-0'>
              <span
                className='text-decoration-underline pointer'
                onClick={() => setShowSignUpModal(true)}
              >
                Stay up to date with special discounts and sales by subscribing
                to our email list.
              </span>
              We promise we'll never spam you or sell your information. *Call or
              visit clinic for FREE body area details.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocialHeroContent;
