import React from 'react';
import { Link } from 'gatsby';
import GRAPHIC from '../../../images/specials/hero/Sep24-Badge.svg';
import './SpecialsHero.css';

function SpecialsHeroContent({ setShowSignUpModal }) {
  return (
    <div id='specials-hero' className='position-relative pt-0'>
      <div className='container py-lg-milan-50 position-relative'>
        <div className='row'>
          <div className='offset-lg-6 col-lg-6 pt-4'>
            {/* only hide on md */}
            <figure className='pb-4 position-relative z-index-100 text-center d-none d-lg-block'>
              <img id='graphic' src={GRAPHIC} alt='Milan Laser Social' />
            </figure>
            <h1 className='milan-text-primary smalltitle text-center'>
              Laser Hair Removal Specials
            </h1>
            <p>
              Not-so-secret ‘stache? Get rid of it for good with 50% OFF
              unlimited laser hair removal. Plus, for the first time ever, we’re
              offering a <strong>FREE body area*</strong> with a regular package
              purchase this month only!
            </p>
            <p>
              Our{' '}
              <Link to='#guarantee' className='milan-text-primary'>
                exclusive Unlimited Package™
              </Link>{' '}
              comes with every purchase and includes FREE touch-ups, so you’re
              covered for life at any of our 380+ nationwide locations.
            </p>
            {/* Desktop Only */}
            <p className='d-none d-md-block'>
              <strong>More Ease, Less Tweeze Bonus:</strong> Receive a{' '}
              <strong>FREE TREATMENT*</strong> on your underarms, bikini line,
              lower legs, face & neck, shoulders, or neckline when you complete
              a complimentary consultation this month.
            </p>
            {/* Mobile Only */}
            <p className='d-md-none'>
              <strong>More Ease, Less Tweeze Bonus:</strong> Receive a{' '}
              <strong>FREE TREATMENT*</strong> when you complete a complimentary
              consultation this month.
            </p>
            <p>
              Stop plucking around and visit your{' '}
              <Link to='/locations/' className='milan-text-primary'>
                local Milan Laser
              </Link>{' '}
              by <strong>September 30</strong> to get half-off unlimited laser
              hair removal for life and one FREE body area! See clinic for
              details.
            </p>
            <div className='my-4 text-center'>
              <Link className='milan-btn milan-cta-btn' to='#consult'>
                Book My Free Consult
              </Link>
            </div>
            <p className='mb-lg-0 milan-fp'>
              <span
                className='text-decoration-underline pointer'
                onClick={() => setShowSignUpModal(true)}
              >
                Stay up to date with special discounts and sales by subscribing
                to our email list.
              </span>
              <span>
                {' '}
                We promise we'll never spam you or sell your information. *Call
                or visit clinic for FREE body area details.
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpecialsHeroContent;
