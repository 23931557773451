import React from 'react'
import { ReferralForm } from '..'

export function ReferralHero({ siteData }) {
  return (
    <section className="hero milan-bg-secondary">
      <div className="container">
        <div className="row">
          <div className="col">
          <h1 className="milan-text-primary text-center pb-1">Claim Your<span className="refer-page-hide"> 60%</span> Discount</h1>
            <h2 className="milan-text-primary text-center subheadsm">Your friend referred you</h2>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <ReferralForm siteData={siteData} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ReferralHero